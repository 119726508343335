/* eslint-disable */
jQuery.validator.addMethod("utf8Mail", function(value, element, param) {
    //return GaU.mailChecker(value);
    return value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/) ? 1:0
});
jQuery.validator.addMethod("mobile", function(value, element, param) {

    if( value.length > 0 ){
        return value.match(/^0[12345679]([-\/. ]?[0-9]{2}){4}$/gi) ? 1:0
    }
    return true;
});
jQuery.validator.addMethod("notEqual", function(value, element, param) {
    return this.optional(element) || value != param;
});

jQuery.validator.addMethod("equal", function(value, element, param) {
    return !(this.optional(element) || value != param);
});
jQuery.validator.addMethod("passwordCheck", function(value, element, param) {
    if( value.length < 8 ){
        return false;
    }
    else if (this.optional(element)) {
        return true;
    } else if (!/[A-Z]/.test(value)) {
        return false;
    } else if (!/[a-z]/.test(value)) {
        return false;
    } else if (!/[0-9]/.test(value)) {
        return false;
    }
    return true;
});
jQuery.validator.addMethod("iv_recapcha_valid", function(value, element) {
    return grecaptcha.execute();
}, 'No message needed, grecaptcha excecuted');
