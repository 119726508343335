/* eslint-disable */
$.Class("DataTable",
    {
        setup : function ($elem, options){
            //save the dom elements
            this.$elem = $elem;

            var defaults = {
                "bProcessing": true,
                'aaSorting': [[ 0, "desc" ]],
                responsive: true,
                "columnDefs": [ {
                    "targets": 'no-sort',
                    "orderable": false
                } ]
            };
            this.options = $.extend(true, defaults, options);

            if( this.$elem.attr("data-sAjaxSource") ){
                this.options['sAjaxSource'] = this.$elem.attr("data-sAjaxSource");
                this.options['bServerSide'] = true;
            }

            if( $elem.attr('data-iDisplayLength') ){
                this.options['iDisplayLength'] = parseInt($elem.attr('data-iDisplayLength'));
            }

            this.options['fnDrawCallback'] = function() {
                /** Initialize tooltips */
                $('[data-toggle="tooltip"]').tooltip();
            }
        },
        // constructor function
        init : function($elem){
            var _this = this;

            //initialisation des columns
            _this.initColumnType();

            //initialisation des tris
            _this.initSort();

            //création de la dataTable
            _this.$elem.dataTable(_this.options);
        },
        initColumnType : function(){
            var _this = this;
            var columns = [];
            _this.$elem.find('th').each(function(){
                columns.push( $(this).attr('data-sType') ? { 'sType': $(this).attr('data-sType') } : null );
            });
            _this.options = $.extend(true, _this.options, { 'aoColumns': columns });
        },
        initSort : function(){
            var _this = this;
            var aSort = [];
            _this.$elem.find('th').each(function(index, value){
                var mode = $(this).attr('data-aSorting');
                if( mode ){
                    if( mode == "asc" || mode == "desc" ){
                        aSort.push( [index, $(this).attr('data-aSorting') ] );
                    }
                }
            });

            if( aSort.length > 0 ){
                _this.options.aaSorting = aSort;
            }
        }
    }
);
